<template>
  <div v-if="!loading">
    <v-card>
      <v-toolbar color="#1a1a27" flat dark height="80px" class="rounded-0">
        <router-link :to="{ name: 'user-payments-list' }" class="mr-3">
          <v-btn icon> <v-icon>mdi-arrow-left</v-icon></v-btn>
        </router-link>
        <v-spacer></v-spacer>
        <v-toolbar-title class="ml-3">
          <h2 class="mb-0">Ödemelerim</h2>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <KTTopbar></KTTopbar>
      </v-toolbar>
      <v-card-text>
        <PaymentViewer
          :totalPrice="total_payment"
          :installments="installments"
        />
      </v-card-text>
    </v-card>
  </div>
  <div
    v-else
    style="height: 250px"
    class="d-flex flex-column justify-content-center align-items-center"
  >
    <v-progress-circular
      indeterminate
      color="primary"
      :size="70"
      width="7"
    ></v-progress-circular>
    <h3 class="mt-5">Lütfen bekleyiniz...</h3>
  </div>
</template>

<script>
import PaymentViewer from "@/view/content/payment/PaymentViewer";
import { isEmptyObject } from "jquery";
import { formatPrice } from "@/core/helper.js";
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";

export default {
  name: "PaymentView",
  components: { PaymentViewer, KTTopbar },
  data() {
    return {
      installments: [],
      total_payment: 0,
    };
  },
  async created() {
    const interval = setInterval(() => {
      if (!isEmptyObject(this.currentUser)) {
        this.getUserPaymentPlans(this.currentUser.id).then((offer) => {
          const paymentPlan = offer[this.paymentPlanId];
          this.total_payment = this.getTotalPrice(paymentPlan);
          this.installments = Object.values(paymentPlan);
        });
        this.loading = false;
        clearInterval(interval);
      }
    }, 500);
  },
  methods: {
    formatPrice,
    getUserPaymentPlans(userId) {
      return new Promise((resolve, reject) => {
        this.$apiService
          .get(`/user-payment-plans/${userId}`)
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getTotalPrice(data) {
      return Object.values(data).reduce((acc, curr) => {
        return acc + parseFloat(curr.installment_amount);
      }, 0);
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    title() {
      return `Ödeme Ekranı`;
    },
    paymentPlanId() {
      return this.$route.params.id;
    },
  },
};
</script>

<style scoped></style>
